import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "../../services/axios";
import { ACTOIN_POST_PRINCIPAL_HOMEPAGE_DETAILS_REQ,
  ACTOIN_POST_PRINCIPAL_DEDUPLICATE_DETAILS_REQ,
  ACTOIN_POST_PRINCIPAL_DEDUPLICATE_HISTORY_DETAILS_REQ,
  ACTOIN_POST_PRINCIPAL_DEDUPLICATE_ACCEPT_REJECT_DETAILS_REQ } from "../SagaActions/SagaActionTypes";
import { getAPI, postAPI } from "./ApiMethods";
import { principalDeuplicate } from "../SagaActions/PrincipalSagaActions";

function* postPrincipalHomepageReqSaga(action) {
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/principal-homepage",
    action?.payload?.model
  );
  action?.payload?.callback(resp);
}

function* postPrincipalDeuplicateReqSaga(action) {
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/dedupdetails",
    action?.payload?.model
  );
  action?.payload?.callback(resp);
}
function* postPrincipalHistoryReqSaga(action) {
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/deduphistory",
    action?.payload?.model
  );
  action?.payload?.callback(resp);
}

function* postPrincipalStatusReqSaga(action) {
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/dedupstatusupdate",
    action?.payload?.model
  );
  action?.payload?.callback(resp);
}

export default function* PrincipalWatcherSaga() {
  yield takeLatest(ACTOIN_POST_PRINCIPAL_HOMEPAGE_DETAILS_REQ, postPrincipalHomepageReqSaga);
  yield takeLatest(ACTOIN_POST_PRINCIPAL_DEDUPLICATE_DETAILS_REQ, postPrincipalDeuplicateReqSaga);
  yield takeLatest(ACTOIN_POST_PRINCIPAL_DEDUPLICATE_HISTORY_DETAILS_REQ, postPrincipalHistoryReqSaga);
  yield takeLatest(ACTOIN_POST_PRINCIPAL_DEDUPLICATE_ACCEPT_REJECT_DETAILS_REQ, postPrincipalStatusReqSaga);


}

