import React from 'react'
import { GlobalFilter, Badges, badgecolors } from "./data";
import Select from "react-select";
import Calendar from '../Calendar'
export const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };

const TableHeader = (props) => {
    const { globalFilter,setGlobalFilter,Row,Col,pageSize,setPageSize,selectFilteroption,onSelectFilteropion,filterOptions} =props
  return (
      <>
      <div className="d-flex justify-content-end">
                  <Row>
                    <Col>

                      <div className="d-flex justify-content-end w-60 flex-wrap">
                        <Select
                          value={selectFilteroption}
                          onChange={onSelectFilteropion}
                          options={filterOptions}
                          placeholder="Select Filter "
                          classNamePrefix="selectform"
                          styles={colourStyles}
                        />
                        {props.selectedDate && <Calendar
                          selectedDate={props.selectedDate}
                          onSelectDate={props.onSelectDate}
                        />}
                        <select
                          className="mb-4 selectpage border me-1"
                          value={pageSize}
                          onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                          {[10, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>

                        <GlobalFilter
                          filter={globalFilter}
                          setFilter={setGlobalFilter}
                        />
                      </div>
                      {props.type && <Badges type={props.type} />}
                    </Col>
                  </Row>
                </div></>
  )
}

export default TableHeader