import moment from 'moment';
import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportHeader from '../components/Table/ReportHeader';
import { useDispatch } from "react-redux";
import { staffdetailsAction, studentDetailsAction } from '../Store/SagaActions/TableDetailSagaAction';
import { badgevalue } from "../components/Table/data";
import ReportTable from '../components/Table';


const PrincipalReports = (props) => {
  const { state } = useLocation();
  const { type, filter, selectedDate } = state;
  const [principalTableData, setPrincipalTableData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectFilteroption, setSelectFilteroption] = useState(filterOptions[0]);

  const [formatDate, setFormatDate] = useState(selectedDate);
  
  let navigate = useNavigate();
  const dispatch = useDispatch();


  const moveToDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    principalReportData();
  }, [])
  const principalReportData = () => {
    const Deptid=sessionStorage.getItem("principalDeptid")

    if (filter != undefined) {
      if (filter == "Present") {
        setSelectFilteroption({ value: 2, label: "Present", short: "P" })
      }
      else if (filter == "Absent") {
        setSelectFilteroption({ value: 3, label: "Absent", short: "A" })
      }
      else if (filter == "Enrolled") {
        setSelectFilteroption({ value: 1, label: "Enrolled", short: "E" })
      }
      else if (filter == "ON") {
        setSelectFilteroption({ value: 5, label: "On Leave", short: "OD" })
      }
      else if (filter == "ALL") {
        setSelectFilteroption({ value: 0, label: "All", short: "ALL" })
      }
    }
    setPrincipalTableData([]);
    let model = {
      deptid: Deptid,
      rdate: moment(formatDate)?.format("YYYY-MM-DD"),
      orgid: "1079",
    };

    if (type == "staff") {
      dispatch(
        staffdetailsAction({
          model,
          callback: (data) => staffdetailsActionResp(data, type),
        })
      );
    }
    else if (type == "student") {
      dispatch(
        studentDetailsAction({
          model,
          callback: (data) => staffdetailsActionResp(data, type),
        })
      );
    }
  }
  const staffdetailsActionResp = (data, type) => {
    if (type == "staff") {
      const datamapped = data?.staffDetails?.map((i, idx) => {
        const { courses_mapped, emp_id, emp_name, status, } = i;
        return {
          "Staff ID": emp_id,
          "Staff Name": emp_name,
          Course: courses_mapped == "not mapped" ? "_" : courses_mapped,
          Status: status,
        };
      });
      const datamappedFilter = data?.staffDetails?.map(i => i.status);
      const option = [... new Set(datamappedFilter)]
      const options = option.map((i, idx) => {
        return ({
          value: idx + 1,
          label: badgevalue(i),
          short: i
        })
      });
      setFilterOptions([{ value: 0, label: "ALL", short: "ALL" }, { value: 1, label: "Enrolled", short: "E" }, ...options]);
      setPrincipalTableData(datamapped);
    }
    if (type == 'student') {
      const datamapped = data?.studentDetails?.map((i, idx) => {
        const { class_name, student_id, student_name, status, } = i;
        return {
          "Student ID": student_id,
          "Student Name": student_name,
          Course: class_name == "not mapped" ? "_" : class_name,
          Status: status,
        };
      });

      const datamappedFilter = data?.studentDetails?.map(i => i.status);
      const option = [... new Set(datamappedFilter)]
      const options = option.map((i, idx) => {
        return ({
          value: idx + 1,
          label: badgevalue(i),
          short: i
        })
      });
      setFilterOptions([{ value: 0, label: "ALL", short: "ALL" }, { value: 1, label: "Enrolled", short: "E" }, ...options]);
      setPrincipalTableData(datamapped);
    }

  };

  const onSelectFilteropion = (e) => {
    setSelectFilteroption(e);
  }

  const onSelectDate = (e) => {
    setFormatDate(e);
    principalReportData();

  };

  return (
    <div>
      <ReportHeader
        title={"Dashboard"}
        subtitle={localStorage.getItem("deptnameforcollegepage") + " Details"}
        pageName={"Reports"}
        moveToDashboard={moveToDashboard}
      />
      {principalTableData.length > 0 &&
        <ReportTable
          title={`List of ${type}`}
          tableData={principalTableData}
          filterOptions={filterOptions}
          selectFilteroption={selectFilteroption}
          onSelectFilteropion={onSelectFilteropion}
          selectedDate={formatDate}
          onSelectDate={onSelectDate}
          fileName={`${type}`}
          type={type}
        />}
    </div>
  )
}

export default PrincipalReports
