import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {

    let loader = useSelector((state) => state?.HomeReducer?.apiStatus);
    return (
        <>
            {
                loader ?
                    <div id="global-loader">
                        <img src={require("../../assets/img/loader.svg").default} className="loader-img" alt="Loader" />
                    </div>
                    : <></>
            }
        </>
    )
}



export default Loader;