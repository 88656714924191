import "./report.css";
import React, { useState, useEffect } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";


import { useTable, useSortBy, useGlobalFilter, usePagination, } from "react-table";
import { badgecolors } from "./data";
import { useSelector } from "react-redux";
import Tablefooter from "./Tablefooter";
import TableHeader from "./TableHeader";






var districtCopyData = [];
const ReportTable = (props) => {

  const {selectFilteroption,onSelectFilteropion,filterOptions}=props
  const [districtFinlaData, setDistrictFinalData] = useState([]);
  const apiStatus = useSelector((state) => state.HomeReducer.apiStatus);
  const [col, setcol] = useState([]);

  useEffect(() => {
    setDistrictFinalData(props.tableData);
    districtCopyData = props.tableData;
    const COLUMNS = Object.keys(props.tableData[0]).map((i) => {
      return {
        Header: i,
        accessor: i,
        className: " ",
      };
    });
    setcol(COLUMNS)

  }, [props]);

  const tableInstance = useTable(
    {
      columns: col,
      data: districtFinlaData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    setGlobalFilter,
    page, // use, page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    var data
    var datakey = props.selectFilteroption?.short
    if (datakey == "ALL") data = districtCopyData
    else if (datakey == "E") data = districtCopyData.filter(ele => ele.Status != "NE")
    else data = districtCopyData.filter(ele => ele.Status == datakey)
    setDistrictFinalData(data)
  }, [props.selectFilteroption])

  return (
    <Row>
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <h4 className="card-title">{props?.title}</h4>
          </Card.Header>
          <Card.Body className="pt-0 example1-table">
            <div className="table-responsive">
              <>
                <TableHeader
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  Row={Row}
                  Col={Col}
                  pageSize={pageSize}
                  setPageSize={ setPageSize}
                  selectFilteroption={selectFilteroption}
                  onSelectFilteropion={onSelectFilteropion}
                  filterOptions={filterOptions}
                />

                {apiStatus ? (
                  <div className="nodata">Fetching Data</div>
                ) : (
                  <>
                    <div id="custom-responsive-table">
                      <table
                        {...getTableProps()}
                        className="table table-hover text-nowrap mb-0 tablebshadow"
                        id="table-to-xls"
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column, i) => {
                                return (
                                  <th
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                    className={column.className}
                                  >
                                    {

                                      <span className="tabletitle">
                                        {column.Header}
                                      </span>
                                    }
                                    <span>
                                      {column.isSorted &&
                                        column?.Header !== "Action" ? (
                                        column.isSortedDesc ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-up"></i>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                        </thead>

                        {rows.length != 0 && (
                          <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);

                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell, i) => {
                                    let badgecolor = (i == 3 && cell?.column.Header == "Status") ? badgecolors(cell?.value) : "";
                                    let cellthreedots = i == 2 ? "threeDots" : "";
                                    return (
                                      <td
                                        data-title={`${cell?.column?.Header}`}
                                        {...cell.getCellProps()}
                                        className={`  ${cellthreedots}`}
                                      >
                                        {badgecolor.length > 0 ? (
                                          <h5>
                                            <Badge
                                              bg=""
                                              className={`${badgecolor}`}
                                              style={{ width: "60%" }}
                                            >
                                              {" "}
                                              {cell.render("Cell")}
                                            </Badge>
                                          </h5>
                                        ) : (
                                          <p className="m-0">
                                            {cell.render("Cell")}
                                          </p>
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </table>
                      {rows.length == 0 && (
                        <div className="nodata">No Data Found</div>
                      )}
                    </div>

                    <Tablefooter
                        districtFinlaData={districtFinlaData}
                        selectedTableType={props.selectedTableType}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        rows={rows}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        fileName={props.fileName}
                        type={ props.type}
                    />
                  </>
                )}
              </>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReportTable;
