import * as SagaActionTypes from "../SagaActions/SagaActionTypes";


const initial = {
  isLoading: false,
  apiStatus: 0,
  isPageDataAvailable: "",
  gethomeDepResp: [],
};

const HomeReducer = (state = initial, action) => {
  switch (action.type) {
    case SagaActionTypes.APISTATUS:
      if (action.payload) {
        return { ...state, apiStatus: state.apiStatus + 1 };
      }
      return { ...state, apiStatus: state.apiStatus > 0 ? state.apiStatus - 1 : 0 };

    case SagaActionTypes.ACTION_POST_SAVEHOMGE_REQ:
      return { ...state, gethomeDepResp: action.payload };

    // case SagaActionTypes.STORE_PAGE_DATA:
    //   return { ...state, isPageDataAvailable: action.payload };

    default:
      return state;
  }
};

export default HomeReducer;
