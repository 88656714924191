import React from "react";
import { Breadcrumb } from "react-bootstrap";

const ReportHeader = (props) => {
  return (
    <div className="breadcrumb-header justify-content-between">
      <div className="left-content">
        <span className="main-content-title mg-b-0 mg-b-lg-1">
          {props?.pageName}
        </span>
      </div>
      <div className="justify-content-center mt-2">
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item
            className="breadcrumb-item "
            onClick={props?.moveToDashboard}
          >
            {props.title}
          </Breadcrumb.Item>
          {/* <Breadcrumb.Item
            className="breadcrumb-item"
            onClick={props?.moveToReport}
          >
            {props?.subtitle+"jnjkhkj"}
          </Breadcrumb.Item> */}
          <Breadcrumb.Item
            className="breadcrumb-item "
            active
            aria-current="page"
          >
            {props?.pageName}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </div>
  );
};

export default ReportHeader;
