import {
  ACTION_POST_LISTOFDISTRICT_REQ,
  ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,
  ACTOIN_POST_STAFF_DETAILS_REQ,
  ACTOIN_POST_STUDENT_DETAILS_REQ,
  ACTOIN_POST_SCWELFARE_DETAILS_REQ
} from "./SagaActionTypes";

export const listOfDistrictMandalsSagaAction = (payload) => ({
  type: ACTION_POST_LISTOFDISTRICT_REQ,
  payload,
});

export const getStudentStaffAttendanceSagaAction = (payload) => ({
  type: ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,
  payload,
});


export const staffdetailsAction = (payload) => ({
  type: ACTOIN_POST_STAFF_DETAILS_REQ,
  payload,
});
export const studentDetailsAction = (payload) => ({
  type: ACTOIN_POST_STUDENT_DETAILS_REQ,
  payload,
});

export const scWelfareDetailsAction = (payload) => ({
  type: ACTOIN_POST_SCWELFARE_DETAILS_REQ,
  payload,
});