import React from 'react'
import { exportToCSV} from "./ExportToCSV"
import { Badge, Button, Card, Col, Row } from "react-bootstrap";


const Tablefooter = (props) => {
    const {rows,pageSize,pageCount,gotoPage,canPreviousPage,previousPage,nextPage,canNextPage,pageIndex,districtFinlaData,selectedTableType,pageOptions,fileName,type}=props
  return (
    <div className="d-block d-sm-flex mt-4 "
                      style={{ textAlignLast: "center" }}
                    >
                      <span className="">
                        {!(rows.length == 0) ? <strong style={{ marginLeft: "10px" }}>
                          Showing {pageIndex * pageSize + 1} - {pageIndex + 1 != pageCount ? pageIndex * pageSize + pageSize : rows.length} Of {rows.length} Records
                        </strong>
                          : <strong style={{ marginLeft: "10px" }}>
                            Showing 0 Records
                          </strong>
                        }

                      </span>
                      <div
                      className="d-flex align-items-center justify-content-center m-1"
                      >

                        <Button
                          onClick={() => exportToCSV(districtFinlaData, fileName,type)}
                          className="download-table-xls-button btn me-2 btn-primary h-excel-btn btn-sm mx-4 d-block"
                          style={{fontSize:".8rem !important",minWidth:"80px !important"}}
                        >Export</Button>
                        {rows.length != 0 &&
                          <span className="">
                            Page{" "}
                            <strong>
                              {pageIndex + 1} of {pageOptions.length}
                            </strong>{" "}
                          </span>
                        }
                      </div>
                      <div
                      // className="ms-sm-auto "
                      >

                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 d-sm-inline  my-1"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          First
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            previousPage();
                          }}
                          disabled={!canPreviousPage}
                        >
                          Prev
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            nextPage();
                          }}
                          disabled={!canNextPage}
                        >
                          Next
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 d-sm-inline  my-1"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          Last
                        </Button>
                      </div>
                    </div>
  )
}

export default Tablefooter