
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./Authentication/auth";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders"
import { Provider } from "react-redux";
import Store from './Store/store';
import { Toaster } from 'react-hot-toast'
import LoaderComp from "./components/LoaderComp/LoaderComp";
import Tracker from '@openreplay/tracker';
import PrincipalDashboard from "./Pages/PrincipalReports";
import Duplicates from "./Pages/Duplicates/index";
import DuplicatesHistory from "./Pages/Duplicates/History";

const tracker = new Tracker({
  projectKey: "NujWyWq1tw9PmAjZQu25",
  ingestPoint: "https://openreplay.syntizen.com/ingest",
  __DISABLE_SECURE_MODE: true

});
tracker.setMetadata('frs-dashboard');

tracker.start();
const App = React.lazy(() => import("../src/shade/layouts/App"));
const Switcherapp = React.lazy(() => import("../src/shade/layouts/Switcherapp"));

const Error404 = React.lazy(() =>
  import("./components/Pages/Authentication/404Error/404Error")
);
const Error500 = React.lazy(() =>
  import("./components/Pages/Authentication/500Error/500Error")
);
const Error501 = React.lazy(() =>
  import("./components/Pages/Authentication/501Error/501Error")
);
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));
//Form


const CollegeReport = React.lazy(() => import("./Pages/CollegeReport"));

const Dashboard = React.lazy(() => import("./Pages/Dashboard/"));
const SingleInfo = React.lazy(() => import("./Pages/Dashboard/SingleInfo"));
const Reports = React.lazy(() => import("./Pages/Reports/"));
const ChangePassword = React.lazy(() => import("./Pages/ChangePassword/"));
const Privacy = React.lazy(() => import("./Pages/Privacy/"));
const Help = React.lazy(() => import("./Pages/Help/"));
const Login = React.lazy(() => import("./Pages/Login/"));

const Detailedreports = React.lazy(() => import("./Pages/DetailedReports"))

const root = ReactDOM.createRoot(document.getElementById("root"));
  document.title = "Falconfly Attendance Dashboard"
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = "/Syntizen_falcon.svg";
root.render(
  <>
    <Toaster />
    <Provider store={Store}>
      <React.Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            {/* <React.Suspense fallback={<LoaderComp />}> */}
            <Routes>
              <Route path={`/`} element={<Auth />}>
                <Route index element={<Login />} />

                <Route
                  path={`/login`}
                  element={<Login />}
                />
                {/* <Route
              path={`/authentication/login`}
              element={<AuthLogin />}
            /> */}
                <Route
                  path={`/signup`}
                  element={<AuthSignup />}
                />
              </Route>
              <Route
                  path={`/help`}
                  element={<Help />}
                />
              <Route path={`/`} element={<App />}>
                <Route
                  path={`/dashboard`}
                  element={<Dashboard />}
                />
                <Route
                  path={`/dashboard/help`}
                  element={<Help />}
                />
                <Route
                  path={`/dashboard/report`}
                  element={<PrincipalDashboard />}
                />
                <Route
                  path={`/details`}
                  element={<SingleInfo />}
                />
                <Route
                  path={`/reports`}
                  element={<Reports />}
                />
                <Route
                  path={`/changepassword`}
                  element={<ChangePassword />}
                />
                <Route
                  path={`/privacy-policy`}
                  element={<Privacy />}
                />
                <Route
                  path={`/detailedreports`}
                  element={<Detailedreports />}
                />
                
                <Route
                  path={`/college-reports`}
                  element={<CollegeReport />}
                />
                <Route
                  path={`/dashboard`}
                  element={<Dashboard />}
                />
                <Route
                path={`/duplicate`}
                element={<Duplicates/>}
                  />
                  <Route
                path={`/duplicatehistory`}
                element={<DuplicatesHistory/>}
                  />
                <Route
                  path={`/pages/Authentication/404error`}
                  element={<Error404 />}
                />
                <Route
                  path={`/pages/Authentication/500error`}
                  element={<Error500 />}
                />
                <Route path="*" element={<Error404 />} />
              </Route>

              <Route>
                <Route
                  path={`/pages/switcher/switcher-1`}
                  element={<Switcherapp />}
                />
              </Route>
              <Route></Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
        <Toaster />
      </React.Fragment>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
