import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from "../../services/axios";
import {
  ACTION_POST_LISTOFDISTRICT_REQ,
  ACTION_POST_LISTOFMANDAL_REQ,
  ACTION_POST_STAFFATTAINDENCE_REQ,
  ACTION_POST_STUDENTATTAINDENCE_REQ,
  ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,
  ACTOIN_POST_STAFF_DETAILS_REQ,
  ACTOIN_POST_STUDENT_DETAILS_REQ,
  ACTOIN_POST_SCWELFARE_DETAILS_REQ
} from "../SagaActions/SagaActionTypes";
import { postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";

// const API_URL = "https://frsdegreeapi.ap.gov.in/api/fabas";

function* districtMandalDetailsSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_FABAS_URL + "/api/fabas/getdistrictmandalwisedetails",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getStudentStaffAttendanceSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_FABAS_URL + "/api/fabas/attendeeattendance",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* staffdetailsActionSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/staffdetails",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
    yield put(actionReqResStatusLoaderSagaAction(false));
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
  finally{
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* studentdetailsActionSaga(action) {
  
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/studentdetails",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
  finally{
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* scWelfaredetailsActionSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/scwelfarereport",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


export default function* TableDetailsWatcherSaga() {
  yield takeLatest(ACTION_POST_LISTOFDISTRICT_REQ, districtMandalDetailsSaga);
  yield takeLatest(ACTOIN_POST_STAFF_DETAILS_REQ, staffdetailsActionSaga);
  yield takeLatest(ACTOIN_POST_STUDENT_DETAILS_REQ, studentdetailsActionSaga);
  yield takeEvery(ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,getStudentStaffAttendanceSaga);
  yield takeEvery(ACTOIN_POST_SCWELFARE_DETAILS_REQ,scWelfaredetailsActionSaga);
}

