import React, { useEffect, useState, useRef, useCallback } from "react";
import "./butoon.css";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  principalDeuplicate,
  principalDeuplicateStatus,
} from "../../Store/SagaActions/PrincipalSagaActions";
import { useDispatch } from "react-redux";
import { Card, Row } from "react-bootstrap";
import Modal from "../../components/Modals/Duplicates";
import { Cards, ApproveReject, thumbnailToimg } from "./Functions";

var tabledata = []
function Duplicates() {
  const [showPopup, setShowPopup] = useState(false); // show modal
  const [tablecol, setTableCol] = useState([]);      // cards data
  const [items, setitems] = useState(Array.from({ length: 6 })) //infinite loading
  const [pagenumber, setPagenumber] = useState(1)    
  const [has, sethas] = useState(true)
  const [approvereject, setapprovereject] = useState("")   
  const [appdata, setappdata] = useState({})


  const dispatch = useDispatch();
  
  //getting cards data
  let orgid = JSON.parse(sessionStorage.getItem("authkey")).orgid;
  const getDeduplicateDataList = () => {
    let model = {
      groupid: orgid,
    };
    dispatch(
      principalDeuplicate({ model, callback: (data) => duplicaeList(data) })
    );
  };

    //accepting/reejecting request
  const userid = `${JSON.parse(sessionStorage.getItem("authkey")).uid}`;
  const approvedEvent = (Status, data) => {
    const { adhid } = data;
    const model = { adhid: adhid, Status: Status, userid: userid };
    dispatch(
      principalDeuplicateStatus({
        model,
        callback: () => getDeduplicateDataList(),
      })
    );
  };


  //mapping data
  const duplicaeList = (data) => {
    const datamapped = data?.map((i, idx) => {
      const { attendeeid, attendeename,
        college_or_classname, designation, thumbnail,
        dattendeeid, dattendeename, dcollege_or_classname, ddesignation, dthumbnail,
      } = i;
      return {
        attendeeid, dattendeeid, attendeename, dattendeename,
        college_or_classname, dcollege_or_classname, designation,
        ddesignation, "thumbnail": thumbnailToimg(thumbnail),
        "dthumbnail": thumbnailToimg(dthumbnail),
        "apreject": <ApproveReject data={i} popUPShow={popUPShow} />
      }
    });
    tabledata = datamapped
    setitems(tabledata.filter((i, idx) => idx < pagenumber * 8))
    setTableCol(tabledata)
  };

  //data to modal
  const popUPShow = (status, data) => {
    setShowPopup(true)
    setapprovereject(status)
    setappdata(data)
  }

  const fetchMoreData = () => {
    setPagenumber(prev => prev + 1)
    setTimeout(() => {
      setitems(tabledata.filter((i, idx) => idx < pagenumber * 6));
    }, 1000);
  };


  useEffect(() => {
    getDeduplicateDataList();
  }, []);

  useEffect(() => {
    if (items.length === tabledata.length) sethas(false);
  }, [items]);



  return (
    // <Card className="text-primary"
    //           style={{ marginTop: "2rem", marginBottom: "2rem", padding: "1rem" }}

    // > Coming Soon</Card>
    <>
      <Modal
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        approvedEvent={approvedEvent}
        approvereject={approvereject}
        appdata={appdata}
      />
      <div className="justify-content-between mt-3">
            <div className="left-content d-none d-md-block d-lg-block">
              <span className="mg-b-0 mg-b-lg-1 font-weight-bold">
              {tabledata.length} Duplicates Data Found
              </span>
            </div>
            <div className="justify-content-center mt-2"></div>
            {/* <div>
            <span> Showing {tablecol.length} at a time</span>
            </div> */}
          </div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={has}
        loader={<h4>Loading...</h4>}
      >
        <Card
          style={{ marginTop: "2rem", marginBottom: "2rem", padding: "1rem" }}
        >
          <Row className="row"
            style={{ marginTop: ".5rem" }}
          >
            {items.map((i, index) => (
              <>
                <Cards key={index} item={i} />
              </>
            ))}
          </Row>
        </Card>
      </InfiniteScroll>
    </>
  );
}

export default Duplicates;
