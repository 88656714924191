import React from 'react'
import { InputGroup } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'

const index = (props) => {
  return (
    <div className="mb-4" style={{ "marginRight": "-50px" }}>
        <InputGroup className="input-group reactdate-pic m-0">
          <InputGroup.Text className="input-group-text m-0">
            <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
          </InputGroup.Text>
          <ReactDatePicker
            className="form-control w-75 m-0"
            selected={props.selectedDate}
            onChange={props.onSelectDate}
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            minDate={
              new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 2,
                1
              )
            }
            showDisabledMonthNavigation
          />
        </InputGroup>
      </div>
  )
}

export default index