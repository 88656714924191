import React from "react";
import {  Modal } from "react-bootstrap";

const WebhookModal = (props) => {
    const { showPopup, setShowPopup, approvedEvent, approvereject, appdata } = props
    const HandleClick = () => {
        approvedEvent(approvereject, appdata)
        setShowPopup(false)
    }
    return (
        <Modal
            size="sm center"
            show={showPopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div
                className="p-4">
                <div className="table ">
                    <h3 className="text-center ">Are you sure
                        you want to {approvereject === "1" ? <span
                        className="text-primary fw-bold"
                        >Approve</span> :  <span
                        className="text-secondary fw-bold"
                        >Reject</span>} ?</h3>
                </div>
                <div className="text-center">
                    <button className="btn btn-success-light m-2"
                        onClick={() => HandleClick()}
                    >Yes</button>
                    <button className="btn btn-danger-light m-2" type="button"
                        onClick={() => setShowPopup(false)}
                    >No</button>
                </div>
            </div>
        </Modal>
    )
}
export default WebhookModal;