// API STATUS
export const APISTATUS = "APISTATUS";


//DEPARTMENTS
export const ACTION_GET_DEPARTMENT_DETAILS_REQ = '@ACTION/GET/DEPARTMENT/DETAILS/REQUEST';

//DashboardDepartments

export const ACTION_GET_DASHBOARD_DEPARTMENT_DETAILS_REQ = '@ACTION/GET/DASHBOARD/DEPARTMENT/DETAILS/REQUEST'
export const ACTION_POST_LOGIN_REQ = "@ACTION/POST/LOCATION/LOGIN/REQUEST";
export const ACTION_POST_DEPARTMENT_DETAILS_WITH_DATE_RANGE = "@ACTION/POST/DEPARTMENT/DETAILS/WITH/DATE/RANGE";
export const ACTION_POST_LOGOUT_REQ = "@ACTION/POST/LOCATION/LOGOUT/REQUEST";
export const ACTION_POST_GET_TODAY_REQ = '@ACTION/POST/TODAY/REQUEST';


export const ACTION_POST_STUDENTATTAINDENCE_REQ = "@ACTION/POST/LOCATION/STUDENTATTAINDENCE/REQUEST";
export const ACTION_POST_STAFFATTAINDENCE_REQ = "@ACTION/POST/LOCATION/STAFFATTAINDENCE/REQUEST";

export const ACTION_POST_CHANGEPASSWORD_REQ = "@ACTION/POST/LOCATION/CHANGEPASSWORD/REQUEST";
export const ACTION_POST_SAVEHOMGE_REQ = "@ACTION/POST/LOCATION/SAVEHOMGE/REQUEST";




export const ACTION_POST_LISTOFDISTRICT_REQ = "@ACTION/POST/LOCATION/LISTOFDISTRICT/REQUEST";
export const ACTION_POST_LISTOFMANDAL_REQ = "@ACTION/POST/LOCATION/LISTOFMANDAL/REQUEST";
export const ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ = "@ACTION/POST/STUDENT/STAFF/ATTEDANCE/REQUEST";
export const ACTOIN_POST_STAFF_DETAILS_REQ = "@ACTION/POST/STUDENT/STAFF/DETAILS/REQUEST";
export const ACTOIN_POST_STUDENT_DETAILS_REQ = "@ACTION/POST/STUDENT/STUDENT/DETAILS/REQUEST";
export const ACTOIN_POST_COLLEGE_DETAILS_REQ = "@ACTION/POST/COLLEGE/DETAILS/REQUEST";
export const ACTOIN_POST_SCWELFARE_DETAILS_REQ = "@ACTION/POST/STATE/SCWELFARE/DETAILS/REQUEST";

//principal 
export const ACTOIN_POST_PRINCIPAL_HOMEPAGE_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/HOMEPAGE/DETAILS/REQUEST";
export const ACTOIN_POST_PRINCIPAL_DEDUPLICATE_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/STUDENT/STAFF/DETAILS/REQUEST";
export const ACTOIN_POST_PRINCIPAL_DEDUPLICATE_HISTORY_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/STUDENT/STAFF/HISTORY/DETAILS/REQUEST";
export const ACTOIN_POST_PRINCIPAL_DEDUPLICATE_ACCEPT_REJECT_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/STUDENT/STAFF/ACCEPT/REJECT/DETAILS/REQUEST";
