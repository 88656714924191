import { all } from 'redux-saga/effects';
import DepartmentWatcherSaga from './DepartmentSaga';
import AuthWatcherSaga from './AuthWatcherSaga';
import DepartmentDeatilsWatcherSaga from './DepartmentDetailsSaga';
import AttaindenceWatcherSaga from './AtendenceDetailsSaga';
import TableDetailsWatcherSaga from './TableDetailsSaga';
import PrincipalWatcherSaga from './PrincipalWatcherSaga'
export default function* rootSage() {
    yield all([ 
        DepartmentWatcherSaga(),
        AuthWatcherSaga(),
        DepartmentDeatilsWatcherSaga(),
        AttaindenceWatcherSaga(),
        TableDetailsWatcherSaga(),
        PrincipalWatcherSaga(),
    ])
}